<template>
    <div class="baseMain">
        <div class="baseSearch clearfloat">
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>组织部门：</span>
                </div>
                <div class="baseSearchOneRight">
                    <div class="baseSearchOneRight">
                        <el-cascader v-model="searchForm.org_id" :options="org_id_list" :props="props"
                                     clearable></el-cascader>
                    </div>
                </div>
            </div>
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>缴费项目：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-select v-model="searchForm.project_id">
                        <el-option v-for="item in project_id_list" :key="item.value" :label="item.label"
                                   :value="item.value" clearable></el-option>
                    </el-select>
                </div>
            </div>
            <!--<div class="baseSearchOne">-->
            <!--<div class="baseSearchOneLeft">-->
            <!--<span>姓名：</span>-->
            <!--</div>-->
            <!--<div class="baseSearchOneRight">-->
            <!--<el-input v-model="searchForm.name" placeholder="请输入内容"></el-input>-->
            <!--</div>-->
            <!--</div>-->
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>学工号：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-input v-model="searchForm.card_number" placeholder="请输入内容" clearable></el-input>
                </div>
            </div>
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>姓名：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-input v-model="searchForm.user_name" placeholder="请输入内容" clearable></el-input>
                </div>
            </div>
            <div class="baseSearchOne">
                <el-button type="primary" style="margin-left: 20px;" @click="search">搜索</el-button>
                <el-button @click="cleanAll">清空</el-button>
            </div>
        </div>
        <div class="baseOperate clearfloat">
            <div class="baseOperateOne">
                <el-button type="primary" @click="openExport" v-if="authorityStatus.arrearsExport.show">导出全部</el-button>
            </div>
            <div class="baseOperateOne tableButton">
                <el-button type="primary" @click="openCall(3)">全部发送催缴通知</el-button>
            </div>
            <div class="baseOperateOne tableButton">
                <el-button type="primary" @click="openCall(2)">选择部分发送催缴通知</el-button>
            </div>
            <!--<div class="baseOperateOne tableButton">-->
            <!--<el-button type="primary" @click="openImport">批量设置现金收款</el-button>-->
            <!--</div>-->
        </div>
        <div class="baseTable" v-loading="tableLoading">
            <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="tableData"
                         :publicTableConfigure="{selection:true}" @selAllDataFun="getSelData($event)">
                <el-table-column prop="project_name" label="缴费项目"></el-table-column>
                <el-table-column prop="card_number" label="学工号"></el-table-column>
                <el-table-column prop="user_name" label="姓名"></el-table-column>
                <el-table-column prop="class_name" label="组织部门"></el-table-column>
                <el-table-column prop="price" label="应缴金额"></el-table-column>
                <el-table-column prop="be_received_price" label="待缴金额"></el-table-column>
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-link class="tableButton" type="primary" @click.prevent="openMoneyInput(scope.row)"
                                 v-if="authorityStatus.arrearsMoney.show">
                            现金收费录入
                        </el-link>
                        <el-link class="tableButton" type="primary" @click.prevent="openCall(1,scope.row)">
                            发送催缴通知
                        </el-link>
                    </template>
                </el-table-column>
            </publicTable>
        </div>
        <div class="basePage" style="margin-top: 4px;">
            <publicPage ref="publicPageUserStudent" :publicPageTotal="{total:total}"
                        @pageChange="getTableData"></publicPage>
        </div>
        <el-dialog title="现金收费录入" v-model="moneyInputShow" width="600px">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px">
                <el-form-item label="学工号">
                    {{selMsg.card_number}}
                </el-form-item>
                <el-form-item label="姓名">
                    {{selMsg.user_name}}
                </el-form-item>
                <el-form-item label="项目名称">
                    {{selMsg.project_name}}
                </el-form-item>
                <el-form-item label="待缴金额">
                    {{selMsg.be_received_price}}
                </el-form-item>
                <el-form-item label="收费金额">
                    <el-input-number v-model="ruleForm.pay_price" controls-position="right" :min="0"></el-input-number>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="saveMoneyInput">确 定</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog title="批量设置现金收款" v-model="importDialog" width="600px">
            <el-form :model="importForm" ref="ruleForm" label-width="auto">
                <el-form-item label="批量设置现金收款模板">
                    <a href="./files/批量设置现金收款模板.xlsx" class="downMuban"
                       style="float: left;" download="批量设置现金收款模板.xlsx">点击下载模板</a>
                </el-form-item>
                <el-form-item label="上传文件">
                    <el-upload style="width: 400px;" ref="importUpload" multiple
                               action="https://jsonplaceholder.typicode.com/posts/"
                               :on-change="importHandleChange" :before-remove="importHandleChange"
                               :file-list="importForm.importFileList"
                               :auto-upload="false" :on-exceed="importHandleExceed"
                               list-type="text">
                        <el-button type="primary">选取文件</el-button>
                        <template #tip>
                            <div class="el-upload__tip">请编辑上一项下载的模板，然后上传</div>
                        </template>
                    </el-upload>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary">确 定</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>

    import publicPage from '../../components/publicPage.vue'

    import publicTable from '../../components/publicTable.vue'

    import XLSX from 'xlsx'

    import {businessGet, businessPost} from '../../api/business.js'

    import {utilsGetAuthority, utilsExportExcel} from '../../utils/utils.js'

    export default {
        name: "payPending",
        components: {
            publicPage,
            publicTable
        },
        data() {
            return {
                options: [
                    {
                        value: '选项1',
                        label: '黄金糕',
                    },
                    {
                        value: '选项2',
                        label: '双皮奶',
                    },
                    {
                        value: '选项3',
                        label: '蚵仔煎',
                    },
                    {
                        value: '选项4',
                        label: '龙须面',
                    },
                    {
                        value: '选项5',
                        label: '北京烤鸭',
                    },
                ],
                org_id_list: [],
                props: {
                    value: 'id',
                    label: 'name',
                    children: 'son',
                    checkStrictly: true
                },
                project_id_list: [],
                searchForm: {
                    org_id: '',
                    project_id: '',
                    card_number: '',
                    user_name: '',
                },
                searchHeight: 0,
                tableLoading: false,
                tableData: [],
                tableDataSel: [],
                total: 0,
                selMsg: null,
                moneyInputShow: false,
                ruleForm: {
                    pay_price: 0,
                },
                rules: {
                    name: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                },
                importDialog: false,
                importForm: {
                    importFileList: [],
                    importFileLimit: 1,
                    importUploadList: [],
                },
                authorityStatus: {
                    arrearsMoney: {
                        method: 'post',
                        url: '/admin/v1/cash_entry',
                        show: false,
                    },
                    arrearsExport: {
                        method: 'get',
                        url: '/admin/v1/arrears_export',
                        show: false,
                    }
                }
            }
        },
        mounted() {
            utilsGetAuthority(this.authorityStatus)
            this.getTreeData();
            this.get_project_id_list();
            this.$nextTick(() => {
                let baseSearchDom = document.querySelector('.baseSearch');
                let baseOperateDom = document.querySelector('.baseOperate');
                let searchHeight = baseSearchDom.offsetHeight + baseOperateDom.offsetHeight;
                this.searchHeight = searchHeight;
                this.cleanAll();
            })
        },
        methods: {
            get_project_id_list() {
                let url = '/admin/v1/projects?page=1&page_pre=500';
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        let newArray = [];
                        res.data.data.list.forEach(item => {
                            let a = {
                                value: item.id,
                                label: item.name
                            }
                            newArray.push(a);
                        })
                        this.project_id_list = newArray;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            getTreeData() {
                let url = '/admin/v1/organization?type=1';
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        this.org_id_list = res.data.data;
                    }
                })
            },
            importHandleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 ` + this.importForm.importFileLimit + ` 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            importHandleChange(file, fileList) {
                this.importForm.importUploadList = fileList;
                let reader = new FileReader();
                reader.readAsBinaryString(file.raw);
                reader.onload = evt => {
                    let data = evt.target.result; // 读到的数据
                    let wb = XLSX.read(data, {type: "binary"});
                    let newArray = wb.Sheets.Sheet1;
                    let noK = ['!margins', '!ref', 'A1', 'B1', 'C1', 'D1'];
                    let searchList = [];
                    for (let k in newArray) {
                        if (noK.indexOf(k) == -1) {
                            let kNameList = k.split('')
                            let num = kNameList[1] - 2;
                            if (searchList[num]) {
                                searchList[num][this.controlList[kNameList[0]]] = newArray[k].w;
                            }
                            else {
                                searchList[num] = {};
                                searchList[num][this.controlList[kNameList[0]]] = newArray[k].w;
                            }
                        }
                    }
                    this.importForm.tableData = searchList;
                    // let outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
                    // console.log(outdata);
                };
            },
            openImport() {
                this.importForm.importFileList = [];
                this.importForm.importFileLimit = 1;
                this.importForm.importUploadList = [];
                this.importDialog = true;
            },
            saveMoneyInput() {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        if (this.ruleForm.pay_price > this.selMsg.be_received_price) {
                            this.$message({
                                showClose: true,
                                message: '录入金额不得大于待缴金额',
                                type: 'warning'
                            });
                            return
                        }
                        let url = '/admin/v1/cash_entry';
                        let data = {
                            receive_id: this.selMsg.id,
                            pay_price: this.ruleForm.pay_price,
                        }
                        businessPost(url, data).then(res => {
                            if (res.data.status == 200) {
                                this.$message({
                                    showClose: true,
                                    message: res.data.message,
                                    type: 'success'
                                });
                                this.moneyInputShow = false;
                                this.getTableData();
                            }
                            else {
                                this.$message({
                                    showClose: true,
                                    message: res.data.message,
                                    type: 'warning'
                                });
                            }
                        })
                    }
                })
            },
            openMoneyInput(val) {
                this.selMsg = val;
                this.ruleForm.pay_price = 0;
                this.moneyInputShow = true;
            },
            openExport() {
                let url = '/admin/v1/arrears?is_export=1';
                for (let k in this.searchForm) {
                    if (this.searchForm[k]) {
                        if (k == 'org_id') {
                            url = url + '&' + k + '=' + this.searchForm[k][this.searchForm[k].length - 1];
                        }
                        else {
                            url = url + '&' + k + '=' + this.searchForm[k];
                        }
                    }
                }
                utilsExportExcel(url)
            },
            exportExcel() {
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    //数据表格
                    let exportList = [];
                    var aoa = [
                        ['支付宝账号', '支付宝账号姓名', '转账金额', '转账人', '转账时间', '转账状态', '备注'],
                    ];
                    let dayinName = ['receiverId', 'receiverName', 'transAmount', 'operaterName', 'fuTransDate', 'statusName', 'remark']
                    exportList.forEach(item => {
                        let a = []
                        dayinName.forEach(item2 => {
                            a.push(item[item2])
                        })
                        aoa.push(a)
                    })
                    //创建book
                    var wb = XLSX.utils.book_new();
                    var ws = XLSX.utils.aoa_to_sheet(aoa);
                    //设置列宽
                    ws['!cols'] = [
                        {width: 20},
                        {width: 20},
                        {width: 20},
                        {width: 20},
                        {width: 20},
                        {width: 20},
                    ];
                    XLSX.utils.book_append_sheet(wb, ws, "sheet1");
                    //输出
                    let exportName = "转账记录.xlsx"
                    XLSX.writeFile(wb, exportName);
                }).catch(() => {
                })
            },
            openCall(sort, val) {
                let data = {};
                let msg = '';
                if (sort == 1) {
                    msg = '此操作将对 ' + val.user_name + ' 进行催缴';
                    data.ids = val.id;
                }
                else if (sort == 2) {
                    if (this.tableDataSel.length > 0) {
                        msg = '此操作将对 ' + this.tableDataSel.length + ' 名学生进行催缴';
                        let idsList = []
                        this.tableDataSel.forEach(item => {
                            idsList.push(item.id)
                        })
                        data.ids = idsList.join(',')
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: '请先选择被催缴的学生',
                            type: 'warning'
                        });
                        return
                    }
                }
                else if (sort == 3) {
                    msg = '此操作将对所有学生进行催缴'
                    data.ids = 'all'
                }
                this.$confirm(msg + ', 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    let url = '/admin/v1/wechat/subscribe/send';
                    businessPost(url, data).then(res => {
                        if (res.data.status == 200) {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'success'
                            });
                            this.getTableData();
                        }
                        else {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'warning'
                            });
                        }
                    })
                }).catch(() => {

                })
            },
            getSelData(val) {
                this.tableDataSel = JSON.parse(JSON.stringify(val));
            },
            cleanAll() {
                this.searchForm.org_id = '';
                this.searchForm.project_id = '';
                this.searchForm.card_number = '';
                this.searchForm.user_name = '';
                this.search();
            },
            search() {
                this.$refs.publicPageUserStudent.currentPage = 1;
                this.getTableData();
            },
            getTableData() {
                let url = '/admin/v1/arrears?page=' + this.$refs.publicPageUserStudent.currentPage + '&page_pre=' + this.$refs.publicPageUserStudent.pageSize;
                for (let k in this.searchForm) {
                    if (this.searchForm[k]) {
                        if (k == 'org_id') {
                            url = url + '&' + k + '=' + this.searchForm[k][this.searchForm[k].length - 1];
                        }
                        else {
                            url = url + '&' + k + '=' + this.searchForm[k];
                        }
                    }
                }
                this.tableLoading = true;
                businessGet(url).then(res => {
                    this.tableLoading = false;
                    if (res.data.status == 200) {
                        this.total = res.data.data.count;
                        let tableData = res.data.data.list;
                        // tableData.forEach(item => {
                        //     item.statusName = this.statusObj[item.status];
                        //     item.typeName = this.typeObj[item.type]
                        // })
                        this.tableData = tableData;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
        }
    }
</script>

<style scoped>

    @import "../../assets/css/base.css";

</style>
